

function get_name(inst:any,inst_title:string, with_ot=false){
    let otlabel = inst.object_type.label
    if(  inst.object_type.cft_ui_label !== undefined
      && inst.object_type.cft_ui_label !== ""){
	otlabel = inst.object_type.cft_ui_label
    }
    let postfix = ""

    if(inst.metatype === "year-version"){
	postfix = "("+inst.year+")"
    }
    else if(inst.metatype === "timeperiod-version"){
	postfix = "("+inst.period+")"
    }
    else if(inst.metatype === "timeline"){
	let tfrom = "?"
	let tuntil = "?"
	if(inst.timeline_from !== undefined){
	    tfrom = inst.timeline_from
	}
	if(inst.timeline_through !== undefined){
	    tuntil = inst.timeline_through
	}
	postfix = "("+tfrom + " - " + tuntil+ ")"
    }
    //inst.object_type.label}: {inst_title} {postfix}</div>)}
    if(with_ot){
	return otlabel + ": " + inst_title + " "+ postfix 
    }
    return inst_title + " " + postfix
}

export default get_name
