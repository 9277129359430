import React from 'react';
import ReactDOM from 'react-dom/client';
import ObjectDash from './ObjectDash'
import reportWebVitals from './reportWebVitals'
import OrgGroupPermAdmin from './OrgGroupPermAdmin'
import OrgChecker from './OrgChecker'
import OrgDash from './OrgDash'
import VoteDetail from './VoteDetail'
import MyConf from './config'
import ProductList from './ProductList'
import UIAdminDash from './UIAdminDash'
import './css/Style.scss'

function cft_render_widget(hd_elm:HTMLElement,data:any){
    MyConf.params = data
    
    let root_elm = hd_elm.querySelector('#react-root-gm-index')
	
    if(root_elm==null){
	return
    }
    const root = ReactDOM.createRoot(root_elm)

    
    
    if(data.view === "obj-dash"){
	root.render(
	    <React.StrictMode>
		<ObjectDash view="list" />
		</React.StrictMode>
	)
    }else if(data.view === "org-checker"){
	root.render(
	    <React.StrictMode>
		<OrgChecker />
		</React.StrictMode>
	)
    }else if(data.view === "group-perm-admin"){
	root.render(
	    <React.StrictMode>
		<OrgGroupPermAdmin bb_group_id={data.group_id} />
		</React.StrictMode>
	)
    }else if(data.view === "vote-detail"){
	root.render(
	    <React.StrictMode>
		<VoteDetail />
		</React.StrictMode>
	)	
    }else if(data.view === "products"){
	root.render(
	    <React.StrictMode>
		<ProductList group_id={data.group_id} />
		</React.StrictMode>
	)
    }else if(data.view === "org-dash"){
	root.render(
	    <React.StrictMode>
		<OrgDash  group_id={data.group_id} />
		</React.StrictMode>
	)
    }else if (data.view === "ui-admin"){
	root.render(
	    <React.StrictMode>
		<UIAdminDash />
		</React.StrictMode>
	)
    }
}

let hd_elm = document.getElementById('gm-index-wrapper') as HTMLElement;
if(hd_elm !== null){
    let params = hd_elm.parentElement!.querySelector('.params')!.textContent;
    if(params === null){
        console.log('error: params not found')
    }else{
        let data = JSON.parse(params)
	cft_render_widget(hd_elm,data)
    }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
