import * as React from 'react'
import WpApi from './wpapi'

import ObjectView from './ObjectView'
import InstancesList from './InstancesList'
import {Instances_mode} from './InstancesList'
import MyConf from './config'

interface MyProps {
    view:string
}
type MyState = {
    object_types:any
    object_type:any
    instance_id:string
    mode:Instances_mode
}
class ObjectDash extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>
	
    constructor(props: MyProps) {
        super(props)

	this.on_chosen_otype = this.on_chosen_otype.bind(this)
	this.on_chosen_instance = this.on_chosen_instance.bind(this)
	this.pop_state = this.pop_state.bind(this)
	this.callback_ov = this.callback_ov.bind(this)



	this.state = {
	    object_types:null,
	    object_type:null,
	    instance_id:"",
	    mode:'timeversion'
	}
    }
    // user clicked 'Back'
    pop_state(e:any){
	console.log('pop-state')
	let p = new URLSearchParams(window.location.search)
	if(p.get('obtype') === null){
	    this.setState({object_type:null})
	} 
	if(p.get('instance') === null){
	    this.setState({instance_id:''})
	}else{
	    let new_id = p.get('instance')
	    if(new_id !== null){
		this.setState({instance_id:new_id})
	    }

	}
    	e.preventDefault()
    }
    componentDidMount(){
	window.addEventListener("popstate",this.pop_state)
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return
	}

	let path = '/objects'
	let d : {[k:string]:string}= {
	    mode: 'get-options'
	}
	this.fetchPromise = WpApi.do_get(path, d,(r) => {
	    this.setState( { object_types: r.options}, this.set_state_objtype_from_url)
	})
    }
    set_state_objtype_from_url(){
	let p = new URLSearchParams(window.location.search)
	let ot = p.get('obtype')
	if(ot !== null){
	    let ot_tup = this.state.object_types[ot]
	    this.setState( {object_type: ot_tup},this.set_state_instance_from_url)
	}
    }
    set_state_instance_from_url(){
	let p = new URLSearchParams(window.location.search)
	let ins = p.get('instance')
	let ot = this.state.object_type.name
	console.log('set-state-instance-from')
	if(ins !== null){
	    let tup = {
		id: ins,
		obtype: ot
	    }
	    console.log(tup)
	    this.setState({ instance_id: tup.id})
	}
    }
    on_chosen_otype(e:any){
	let ot = e.target.value
	console.log(`chosen otype ${ot}`)
	const url = new URL(window.location.href)
	url.searchParams.set("obtype",ot)
	window.history.pushState({obtype:ot},"",url)
	let ot_tup = this.state.object_types[ot]

	this.setState({ object_type: ot_tup}  )
	//
    }
    render_ot_selector(){
	if(this.state.object_types === null){
	    return null
	}
	let olist = Object.entries(this.state.object_types).sort((x:any,y:any) => {
	    if(x[1].label >= y[1].label){
		return 1
	    }else{
		return -1
	    }
	})
	let options = olist.map(([k,tup]:[string,any]) => {
	    return (<option key={k} value={k}>{tup.label}</option>)
	})
	return (
	    <div>
		<select onChange={this.on_chosen_otype}>
		 <option value="">- kies een object-type - </option>
		{options}
		</select>
	    </div>
	)
    }
    on_chosen_instance(tup:any,e:any){
	console.log('on-chosen-inst')
	const url = new URL(window.location.href)
	url.searchParams.set("instance",tup.id)
	console.log(url)
	if(e.ctrlKey){
	    console.log('with CTRL')
	    let w= window.open(url, '_blank')
	}else{
	    window.history.pushState({instance:tup.id},"",url)
	    this.setState({instance_id:tup.id})
	}
    }
    
    render_instances_list(){
	let ot = this.state.object_type
	if(ot === null){
	    return
	}
	let key = ot.name + '-' + this.state.mode
	return (
	    <div>
		<a href="?">back</a>
		<InstancesList
		    showheader={true}
		    key={key}
		    filter={{}}
		    object_type={ot}
		    mode={this.state.mode}
		    cb={this.on_chosen_instance}
		/>
	    </div>
	)
    }
    change_mode_to(k:string){
	console.log('change mode to '+k)
	if(k === "timeversion"){
	    this.setState({mode:"timeversion"})
	}else{
	    this.setState({mode:"navigation"})
	}
    }
    render_mode_selector(){
	return null
	let options = {
	    "navigation": "laat geen timeversions zien",
	    "timeversion": "laat wel timeversions zien"
	}
	return (
	    <div className="mode-selector">
		Kies browser-modus:
	    {Object.entries(options).map(([k,lab]:[string,string])=>{
		    return (
			<div key={k}> <input
				  checked={k=== this.state.mode}
				  type="radio" name="mode"
				  onChange={()=>{
				      this.change_mode_to(k)
				  }}
			    /> [{k}] : {lab}</div>
		    )
		})}
	    </div>
	)
    }
    callback_ov(e:any){
	console.log(e)
	if(e.cmd === "set-instance-id"){
	    let iid = e.instance_id
	    this.setState({instance_id: iid})
	    const url = new URL(window.location.href)
	    url.searchParams.set("instance",iid)
	    window.history.pushState({instance:iid},"",url)
	}
    }
    render(){
	let config = {
	    params: MyConf.params,
	    view:"obj-dash"
	}
	if(this.state.instance_id !== ""){
	    return (
		<div className="object-view">
		    <ObjectView key={this.state.instance_id}
			instance_id={this.state.instance_id}
			config={config}
			callback_parent={this.callback_ov}
		    />
		    </div>
	    )
	}
	return (
	    <div className="object-list">
		{this.render_mode_selector()}
		{this.render_ot_selector()}
		{this.render_instances_list()}
	    </div>
	)
    }

}

export default ObjectDash

