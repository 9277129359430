import * as React from 'react'
import WpApi from './wpapi'

import GroupAdminPermSelect from './GroupAdminPermSelect'

interface MyProps {
    bb_group_id:string
}
type MyState = {
    organisation:any
    show_reset_really:boolean
    relationtypes:any
    permsettings:any
    subgroups:Array<any>
    subgroup:any
}

class OrgGroupPermAdmin extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>

    constructor(props: MyProps) {
        super(props)
	this.state = {
	    organisation:{},
	    show_reset_really:false,
	    permsettings:{},
	    subgroups:[],
	    subgroup:null,
	    relationtypes:{}

	}
    }	
    componentDidMount(){

	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return
	}

	let path = '/group-adm'
	let d = {
	    mode: 'get-index',
	    gid: this.props.bb_group_id

	}
	this.fetchPromise = WpApi.do_get(path, d,(r) => {
	    console.log('got r')
	    this.setState({
		organisation: r.org,
		relationtypes:r.relationtypes,
		permsettings: r.permsettings,
		subgroups: r.confi_groups
	    })
	});
    
    }

    render_child_tup(tup:any){
	//if(this.state.
	return (
	    <div key={tup.id} className="tree-item-child">
		<div className="details">
		    <div className="label">
 			{tup.object_type.label}: {tup.title}
	    </div>
	    {this.render_perm_selectors(tup.id,'all')}
		</div>
		{tup.children.map((child:any) => this.render_tree_children_block(tup.id,child))}
	    </div>
	)
    }
    render_tree_children_block(object_id:number,tup:any){
	if(tup === undefined){return}
	if(tup.relation === undefined){ return }
	let rid = tup.relation.rid

	let r = this.state.relationtypes[rid]
	let rlabel = tup.relation.inverse ?  r.tup.cft_inv_rel_label : r.tup.cft_rel_label
	
	return (
	    <div key={tup.id} className="tree-item-block">
		<div className="details relation">
		<div className="label"> {rlabel} </div>
		{this.render_perm_selectors(object_id,rid)}
		</div>
		{tup.list.map((child:any)=>this.render_child_tup(child))}
	    </div>
	)
    }
    render_perm_selectors(object_id:number,block:string){
	
	return (
	    <div className="selectors">
		{this.render_select('public',object_id,block)}
	    {this.state.subgroup !== null ? (
		this.render_select(this.state.subgroup,object_id,block)
	    ) : null}

	    </div>
	)

    }

    render_select(subgroup:any,object_id:number, block:string){
	let curval = ''
	let sg_id = ''
	if(subgroup === 'public'){
	    sg_id = 'public'
	    let settings = this.state.permsettings.values
	    if(object_id in settings){
		if( block in settings[object_id]){
		    curval = settings[object_id][block]
		}
	    }
	}else{
	    sg_id = 'confi-'+subgroup.id
	    if(subgroup !== undefined){
		let settings = subgroup.permsettings
		if(object_id in settings){
		    if( block in settings[object_id]){
			curval = settings[object_id][block]
		    }
		}
	    }
	}

	return (
	    <GroupAdminPermSelect
		key={`${sg_id}-${object_id}-${block}`}
		bb_group_id={Number(this.props.bb_group_id)}
		choices={this.state.permsettings.choices}
		subgroup={subgroup}
		object_id={object_id}
		block={block}
		value={curval}
	    />
	)
    }
    render_timeversion(tup:any){
	return (
	    <div key={tup.id} className="timeversion tree">
		{this.render_child_tup(tup)}
	    </div>
	)
    }
    render_timeversions(list:any){
	return (
	    <div className="timeversions tree-item-block">
		Timeversions:
		{Object.entries(list).map(([k,tup]:[string,any])=> this.render_timeversion(tup))}
	    </div>
	)
    }
    render_org(){
	let org = this.state.organisation
	if(org.id === undefined){
	    return
	}
	return (
	    <div className="org-item">
		<div className="table-headers">
		    <div className="padding"></div>
		    <div className="labels">
		<div className="label public"> Public </div>
		{this.render_choose_subgroup()}
		    </div>
		</div>
		{this.render_timeversions(org.timeversions)}
	    </div>
	)
    }
    reset_perm(){
	let path = '/group-adm'

	let d = {
	    cmd: 'reset-permset-all',
	    gid: this.props.bb_group_id,
	    
	}
	WpApi.do_post(path,d,(r:any)=>{
	    console.log(r)
	    window.location.reload()
	})
    }
    render_reset_perm(){
	return (
	    <div className="perm-reset">
		{this.state.show_reset_really ? (
		    <div className="heads-up">
			<div>LET OP, hiermee worden alle settings voor deze groep teruggezet naar standaard settings </div>
			<button onClick={(e:any)=> this.reset_perm()}>
			Yes, Reset the permissions </button>
		    </div>
		):(
		    <div>
			<button onClick={(e:any)=>{
				    this.setState({ show_reset_really:true})
				}}>reset permissions</button> </div>
		)}
	    </div>
	)
    }
    render_choose_subgroup(){
	return (

	    <div className="choose-subgroup">

	    {this.state.subgroups.map((tup:any)=> (
		<div key={tup.id} className="subgroup-option">
		    <input id={tup.id} type="radio" name="subgroup"
		onChange={(e:any) => {this.setState({subgroup:tup})}}
		    /> <label htmlFor={tup.id}>{tup.name} </label> </div>
	    ))}
		</div>
		
	)
    }
    render(){
	let label = ''
	if(this.state.organisation.title !== undefined){
	    label = this.state.organisation.title
	}

	return (
	    <div className="group-admin">
		<h4>Organisation: {label}</h4>
		{this.render_reset_perm()}
		{this.render_org()}
	    </div>
	)
    }
}

export default OrgGroupPermAdmin


