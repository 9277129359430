import * as React from 'react';

import ReactModal from 'react-modal';
import HdaVoteGrid from './HdaVoteGrid';
import HistoryGrid from './HistoryGrid';


interface MyProps {
    cb_parent:(e?: any) => void
}


type MyState = {
    showModal:boolean,
    label: string,
    history_hdr_label:string,
    with_history:boolean,
};

class VoteGridModal extends React.PureComponent<MyProps,MyState> {
    private voteGrid =  React.createRef<HdaVoteGrid>();
    private history_grid =  React.createRef<HistoryGrid>();

    public after_vote(e:any){
	console.log('after vote')
	// send signal to parent: the HdaList
	this.props.cb_parent(e)
	this.setState({showModal:false})
    }
    constructor(props: MyProps) {
        super(props)
    	this.handleCloseModal = this.handleCloseModal.bind(this)
	this.after_vote = this.after_vote.bind(this)
	this.state = {
	    showModal:false,
	    with_history:false,
	    history_hdr_label:'undef',
	    label:'undef'
	}
    }
    public handleCloseModal(){
	this.setState({ showModal: false });
    }
    public init(data:any){
	let pop_label = data.label === undefined ? '' : data.label

	console.log(data)
	this.setState({
	    label:pop_label,
	    showModal:true,
	    with_history: data.with_history,

	})
	if(data.label !== undefined){
	    this.setState({})
	}
	if(data.with_history){
	    this.setState({
	    	history_hdr_label: data.history_popup.label
	    })
	    setTimeout(()=>{
		if(this.history_grid.current !== null){
		    this.history_grid.current!.init(data.history_popup)
		}
	    },400);
	}
	setTimeout(()=>{
	    this.voteGrid.current!.init(data)
	},300);
    }

    render_history_grid(){
	return this.state.with_history? (
	    <div className="wrapper-for-history">
		<div className="grid-header label">History</div>
		<HistoryGrid ref={this.history_grid} />
		</div>
		
	) : null
    }
    
    public render(){
	const customStyles = {
	    content: {
		top: '10%',
		left: '10%',
		right:'20%',
		bottom:'20%'
		
	    },
	};
	return (
	    <ReactModal
	            ariaHideApp={false}
	            contentLabel="my test modal"
	            style={customStyles}
	            isOpen={this.state.showModal}
                    onRequestClose={this.handleCloseModal}
                    shouldCloseOnOverlayClick={true}
		>
		<div className="popup-header">
		    <div className="label">{this.state.label}</div>
		    <div className="popup-buttons">

			<span className="cf-icon-btn" data-balloon="Sluit" data-balloon-pos="down" onClick={this.handleCloseModal}><i className="bb-icon-l bb-icon-times"/></span>
		    </div>
		</div>
		<div className="cft-popup-body">
		    <HdaVoteGrid ref={this.voteGrid}   cb_parent={this.after_vote}/>
		    {this.render_history_grid()}
		</div>
	     </ReactModal>
	)
    }    
}

export default VoteGridModal;
