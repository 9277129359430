import * as React from 'react'
import WpApi from './wpapi'
import ObjectView from './ObjectView'
import HrefLink from './HrefLink'
import get_nice_label from './ObjectNiceName'

import MyConf from './config'

interface MyProps {
    group_id:string
}
type MyState = {
    organisation:any
    new_timeversions:Array<any>
    relationtypes:any
    path_to_org:Array<any>
    object:any
    instance_id:string
}

class OrgDash extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>

    constructor(props: MyProps) {
        super(props)

	this.callback_ov = this.callback_ov.bind(this)
	this.pop_state = this.pop_state.bind(this)

	this.state = {
	    new_timeversions: [],
	    path_to_org: [],
	    organisation:null,
	    object:null,
	    instance_id:"",
	    relationtypes:{}
	}
    }
    pop_state(e:any){
	console.log('pop-state')
	let p = new URLSearchParams(window.location.search)
	if(p.get('instance') === null){
	    this.setState({instance_id:''})
	}else{
	    let new_id = p.get('instance')
	    if(new_id !== null && new_id !== this.state.instance_id){
		this.load_obj_for(new_id)
	    }
	}
    	e.preventDefault()
    }
    load_obj_for(obj_id:string){
	this.setState({instance_id: obj_id})
	let path = '/group-content'
	let d : {[k:string]:string}= {
	    mode: 'get-object',
	    group_id: this.props.group_id,
	    object_id: obj_id
	}
	WpApi.do_get(path, d,(r) => {
	    console.log('got r')
	    if(r.object !== undefined){
		this.setState({
		    object: r.object,
		    path_to_org: r.path_to_org
		})
	    }
	})

    }

    componentDidMount(){
	window.addEventListener("popstate",this.pop_state)
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return
	}
	let path = '/group-content'
	let d : {[k:string]:string}= {
	    mode: 'get-org-object',
	    group_id: this.props.group_id
	}
	let p = new URLSearchParams(window.location.search)
	let object_id = p.get('instance')
	if(object_id !== null){
	    d.object_id = object_id
	    d.mode = "get-object"
	    this.setState({instance_id:object_id})
	}
	this.fetchPromise = WpApi.do_get(path, d,(r) => {
	    console.log('got r')
	    if(r.org !== undefined){
		this.setState({
		    organisation: r.org,
		    relationtypes:r.relationtypes,
		})
	    }else if(r.object !== undefined){
		this.setState({
		    object: r.object,
		    path_to_org: r.path_to_org
		})
	    }
	});

    }
    open_popup(e:any, cb:(r:any)=>void){
        console.log(`open-popup cmd: ${e.cmd}`)
    }

    set_instance_id(iid:string){
	    this.load_obj_for(iid)
	    const url = new URL(window.location.href)
	    url.searchParams.set("instance",iid)
	    window.history.pushState({instance:iid},"",url)
    }
    
    callback_ov(e:any){
	console.log(e)
	if(e.cmd === "set-instance-id"){
	    let iid = e.instance_id
	    this.set_instance_id(iid)
	}

    }
    render_org(){
	let org = this.state.organisation
	if(org === null){ return }
	let cfg = {
	    params: MyConf.params,
	    view : "org-dash"
	    
	}
	if(org.title === undefined){
	    return (<div>--</div>)
	}
	return (
	    <div className="org-item">
		<ObjectView callback_parent={this.callback_ov} instance_id={org.id} config={cfg}/>
	    </div>
	)
    }
    render_obj(){
	let obj = this.state.object
	if(obj === null){ return }
	let cfg = {
	    params: MyConf.params,
	    view : "org-dash"
	    
	}
	return (
	    <div className="obj-item">
		<ObjectView key={obj.id} callback_parent={this.callback_ov} instance_id={obj.id} config={cfg}/>
	    </div>
	)

    }
    render_path_elm(x:any){
	if(x.winner === undefined){return null}
	let params = "instance="+x.winner.id
	if(x.winner.bbgrouplink !== undefined){
	    params = x.winner.bbgrouplink + '?' + params
	}
	let label = get_nice_label(x.winner,x.winner.title)

	return (
	    <div key={x.winner.id} className="item" 
	       onClick={(e:any)=>{e.preventDefault();this.set_instance_id(x.winner.id)}}> 
	       &gt; <a href={params}>{label} </a>
	    </div>
	)
    }
    render_path_to_org(){
	let path = this.state.path_to_org.reverse()
	if(path.length===0){return}
	return (
	    <div className="bread-crums">
		{path.map((x:any) => this.render_path_elm(x))} 
	    </div>
	)
	// 
		    // 
    }
    render(){
	return (
	    <div className="org-dash">
	    {this.render_path_to_org()}
		{this.render_org()}
		{this.render_obj()}
	    </div>
	)
    }
}

export default OrgDash
