
import MyConf from './config'

interface HrefLinkProps {
  /** The text to display inside the button */
    page: "obj-dash" | "org-dash" | "vote"
    params:string
    text?:string
    with_icon?:string

}


export default function HrefLink({page,params,text,with_icon}: HrefLinkProps){

    let new_tab = (new MyConf()).get().open_in_new_tab
    let deploymode = MyConf.params.deploymode
    console.log('deploymode '+deploymode)
    
    let url = "?" + params
    if(deploymode === "develop"){
	if(page === "obj-dash"){
	    url = "obj_dash.html?"+params
	}
	if(page === "org-dash"){
	    url = "org_dash.html?"+params
	}
	if(page === "vote"){
	    url = "vote_detail.html?" + params
	}
    }else{
	new_tab = true
	let langprefix = "/nl"
	if(page === "obj-dash"){
	    url = langprefix + "/cft-objects-dash/?"+params
	}
	if(page === "org-dash"){
	    url = langprefix + "/cft-org-dash/?"+params
	}
	if(page === "vote"){
	    url = langprefix + "/cft-vote/?" + params
	}
    }
    if(page === "vote"){
	return (
	    <a className="cf-icon-btn icon-large"
	      target="_blank" href={url} 
	      data-balloon-pos="up" data-balloon="Vote-details" >
	   <i className="bb-icon-comment-dots bb-icon-l"></i>
	   </a>
	)
    }
    if(params.startsWith("http")){
	url = params
    }
    else if(params.startsWith("/nl")){
	url = params
    }

    let cls = "cft-link cft-small-icon "+deploymode
    let txt = (<span>{text}</span>)
    if( text === undefined){
	cls += " align-right"
	txt = (
	    <img alt="" src={require("./img/open-link-icon-11.png")} />
	)
    }
    else{
	if(with_icon !== undefined){
	    txt = (<div className="text-with-icon">{txt}  
	    <img alt="" src={require("./img/open-link-icon-11.png")} />
		   </div>)
	}
    }
    return new_tab ? (
	<a onClick={(e:any)=> {e.stopPropagation()}} 
	    className={"blank-target "+cls} rel="noreferrer"
	    target="_blank" href={url}>{txt}</a>
    ) : (
	<a onClick={(e:any)=> {e.stopPropagation()}} 
	className={cls} href={url}>{txt}</a>
    )
}
