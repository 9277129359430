import * as React from 'react'
import WpApi from './wpapi'
import RelationRow from './RelationRow'
import AddRelationModal from './modals/AddRelationModal'

interface cb_after_vote_event {
    (r:any): void;
}


type MyState = {
    attr:any
    target:any
    tab:string
    user:any
    field:any
    relations:any
}

interface MyProps {

}
class VoteDetail extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>
	private callback_after_vote_event :cb_after_vote_event = (x)=>{ console.log("dummy")}

    private add_relation_popup =  React.createRef<AddRelationModal>();
	
    constructor(props: MyProps) {
        super(props)
	this.open_popup = this.open_popup.bind(this)
	this.after_vote_event = this.after_vote_event.bind(this)
	this.state = {
	    attr:null,
	    user:null,
	    relations:{},
	    tab:'sq',
	    target:null,
	    field:null
	}
    }
    componentDidMount(){
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return
	}
	let p = new URLSearchParams(window.location.search)
	let aid = p.get('attr_id')
	let path = '/block-attr'
	if(aid !== null){
	    let d = {
		cmd: 'get-detail',
		attr_id:  aid
	    }
	    this.fetchPromise = WpApi.do_get(path, d,(r:any) => {
		console.log(r)
		this.setState({
		    attr:r.attr,
		    user:r.user,
		    target:r.target,
		    field:r.field
		})
	    })
	    WpApi.do_get('/features', {attr_id:aid, mode:'get-vote-options'},(r:any) => {
		console.log(r)
		this.setState({relations:r.relations})
	    })
	}
    }
    public open_popup(e:any, cb:(r:any)=>void){
        console.log(`open-popup cmd: ${e.cmd}`)
	/*
	let votetypes = [
            'choice-vote',
            'inputs-comment-vote',
            'comment-vote',
	    'relation-vote',
            'text-vote',
            'number-vote'
        ]
        if(votetypes.includes(e.cmd)){
            this.vote_popup.current!.init(e)
            }
	else if(e.cmd == 'show-history'){
	    this.history_popup.current!.init(e)
	}
	*/
	if(e.cmd === 'add-relation'){
	    this.add_relation_popup.current!.init(e)
	}
        // point callback to the calling Section
        this.callback_after_vote_event = cb;
    }
    after_vote_event(e:any){
	console.log('got-vote-event from popup')
	console.log(e)
	if(e.cmd === 'add_relation'){
	    let d = {...e,  attr_type: 'add_relation'}
	    if(e.itema_name === "claim" || e.itemb_name === "claim"){
		console.log('is an attr-rel')
		d.attr_type = 'attr_relation'
		d.inverse = true
	    }else{
		console.log('is not an attr-rel')
	    }
		

	    // FIXME: deze route werkt nu alleen voor attr_relation
	    //  maar moet ook werken voor daarbinnen, bijv domain-block
	    //   (en ik betwijfel of je dan d.inverse moet laten staan op true !)
	    WpApi.do_post('/block-attr',d, (r) =>{
		console.log(r)
		if(r.status === "ok"){
		    window.location.reload()
		}
		if(r.error){
		    this.add_relation_popup.current!.set_warning(r.error)
		}
	    })
	}
    }
    render_attr(){
	if(this.state.attr === null){
	    return
	}
	let tup = this.state.attr
	let user = this.state.user
	let fld = this.state.field
	let target = this.state.target
	let value_label = tup.value
	let fld_lab = ""
	if(fld !== undefined  && fld.choices !== undefined){
	    fld_lab = fld.label
	    let choices = fld.choices
	    if(tup.value in choices){
		value_label = choices[tup.value]
	    }
	}
	let t2 = false
	let target2_label = ''
	if(tup.rattr !== undefined && tup.rattr.attr_type === "relation_nn"){
	    fld_lab = tup.rattr.rel.label
	    if( tup.rattr.rel.ui_label !== undefined){
		fld_lab = tup.rattr.rel.ui_label
	    }
	    t2 = true
	    target2_label = tup.rattr.related.title
	}

	return (
	    <div className="attr">
		<div>Gemaakt op tijdstip: {tup.time} </div>
	        <div>Gemaakt door: {user.email} </div>
	        <div>Status: {tup.status} </div>
		{tup.attr_type === "attr_ref" ? "" : (
		    <div>Waarde / inhoud: {value_label} </div>
		)}
		<div>Betreft: {target.title} </div>
		<div>Aspect: {fld_lab} </div>
		{t2 === null ? null : (
		    <div>Keuze: {target2_label} </div>
		)}
	    </div>
	)
    }

    render_sq(){
	if(this.state.tab !== 'sq'){
	    return
	}
	let cfg = {
	    view: 'vote-detail'
	}
	let tup = this.state.relations.sq
	let attr = this.state.attr
	if(attr === null){return}
	if(tup === undefined){return}
	return (
	    <div>
		<RelationRow instance={attr}
	           config={cfg}
	           tup={tup} post_id={Number(attr.id)}
	           cb_parent={this.open_popup}
		/>
	    </div>
	)
    }
    render_ass(){
	if(this.state.tab !== 'ass'){
	    return
	}
	let cfg = {
	    view: 'vote-detail'
	}
	let tup = this.state.relations.ass
	let attr = this.state.attr
	if(attr === null){return}
	if(tup === undefined){return}
	return (
	    <div>
		<RelationRow instance={attr}
	           config={cfg}
	           tup={tup} post_id={Number(attr.id)}
	           cb_parent={this.open_popup}
		/>
	     </div>
	)
    }
    render_tab_content(){
	return (
	    <div className="tab-content">
		{this.render_sq()}
		{this.render_ass()}
	    </div>
	)
    }
    render_tab_nav(){
	let fields = {
	    'sq' : 'SQ + author',
	    'ass' : 'Assurance'
	}
	let entries = Object.entries(fields)
	return (
	     <div className="details-nav top-tab-nav">
                <div className="main-container">
                    <div className="layout-nav-items">
			{entries.map(([k,label]:[string,any],i:number) => {
                            let cl = "nav-item details-nav-item"
                            if(k === this.state.tab){
                                cl += " active"
                            }
			    return (
				<div onClick={(e)=>{this.setState({tab:k})}}
				     className={cl} key={i}>
				    {label}
				</div>)
			})}
		    </div>
		</div>
	     </div>
	)
    }
    render(){
	return (
	    <div>
		{this.render_attr()}
	        <div className="tabs-container">
		    {this.render_tab_nav()}
		    {this.render_tab_content()}
	    </div>
		<AddRelationModal ref={this.add_relation_popup} cb_parent={this.after_vote_event}  />
	    </div>
	)
    }

}

export default VoteDetail
